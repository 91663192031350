import { Notificacion } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import useForm from "@customHooks/useForm";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { createCheckoutSession, getFolioContratoCompra, validateCodigoDescuento } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import stripe from "@helpers/stripe/stripe";
import { typesOpcionesPagoCompra } from "@helpers/types/typesOpcionesPagoCompra";
import { FormControl as MUIFormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Swal from "sweetalert2";

const setPrecio = (codigoDescuento, precios, setPreciosXCodigo) => {
	switch (codigoDescuento) {
		case precios.clickPlus2024.codigo:
			setPreciosXCodigo(precios.clickPlus2024);
			break;

		case precios.clickPlusPremium.codigo:
			setPreciosXCodigo(precios.clickPlusPremium);
			break;

		case precios.gcPlus.codigo:
			setPreciosXCodigo(precios.gcPlus);
			break;

		default:
			console.log("No hay ningún código que coincida con el ingresado o no se ingresó ningún dato");
			break;
	}
};

function esValido(json) {
	return Object.keys(json).length > 0;
}

const FormCodigoDescuento = (props) => {
	const {
		precios,
		compraExterna,
		states: { stateCodigoAplicado, stateCodigoDescuento, statePreciosXCodigo },
	} = props;

	const history = useHistory();

	const { contextValue, setContextValue } = useContext(UserContext);

	const { codigoAplicado, setCodigoAplicado } = stateCodigoAplicado;

	const { codigoDescuento, setCodigoDescuento } = stateCodigoDescuento;

	const { preciosXCodigo, setPreciosXCodigo } = statePreciosXCodigo;

	const [idCodigoDescuento, setIdCodigoDescuento] = useState(0);

	const [folioContrato, setFolioContrato] = useState("");

	const [loading, setLoading] = useState(false);

	const [opcionesPago, setOpcionesPago] = useState([]);

	const [valueRadioButton, setValueRadioButton] = useState("");

	const [inputValue, handleInputChange] = useForm("");

	const handleSubmitValidacionCodigo = async (e) => {
		e.preventDefault();

		if (inputValue === "") {
			return NotificationManager.error("Ingresa un código de descuento");
		}

		try {
			const { valido, idCodigo } = await validateCodigoDescuento(inputValue);

			NotificationManager.success("¡Código aplicado!");

			setCodigoAplicado(valido);

			setCodigoDescuento(inputValue);

			setPrecio(inputValue, precios, setPreciosXCodigo);

			setIdCodigoDescuento(idCodigo);
		} catch (error) {
			return NotificationManager.error("Este código es inválido");
		}
	};

	const handlePagarAhora = async () => {
		try {
			if (valueRadioButton === "") {
				return NotificationManager.error("Elige una opción de pago");
			}

			setLoading(true);

			let stripeIdPrice = "";

			switch (valueRadioButton) {
				case typesOpcionesPagoCompra.contado:
				case typesOpcionesPagoCompra.msi:
					stripeIdPrice = preciosXCodigo.stripeIdPrice;
					break;

				case typesOpcionesPagoCompra.credito:
					stripeIdPrice = preciosXCodigo.credito.stripeIdPrice;
					break;

				default:
					break;
			}

			//todo:Revisar los datos que se envian para posteriormente hacer el rsgistro y luego generar el contrato.
			const sessionData = {
				success_url: `${window.location.origin}${dashboardRoutesPaths.pagos.compra.compraRealizada}${compraExterna ? "?license-acquired=true": "?license-acquired=false"}`,
				cancel_url: `${window.location.origin}${dashboardRoutesPaths.pagos.compra.compraLicencia}${compraExterna ? "?new-license=true" : "?new-license=false"}`,
				line_items: [{ price: stripeIdPrice, quantity: 1 }],
				mode: "payment",
				currency: compraExterna ? "usd": "mxn",
				metadata: {
					folioContrato,
					metodoPago: valueRadioButton,
				},
			};


			const checkoutSession = await createCheckoutSession(sessionData);

			const { isConfirmed } = await Swal.fire({
				title: "Saliendo de Click+",
				text: "Estás a punto de ser redirigido al sitio de pago",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Entendido",
				cancelButtonText: "Cancelar",
				confirmButtonColor: "#0d6efd",
				allowOutsideClick: false,
			});

			if (!isConfirmed) {
				setLoading(false);

				return Notificacion("info", "El pago fue cancelado");
			}

			delete contextValue.preciosDescuento;

			setContextValue({
				...contextValue,
				compra: {
					...contextValue.compra,
					stripeSession: checkoutSession,
					codigoAplicado,
					idCodigoDescuento,
					preciosSeleccionados: preciosXCodigo,
					metodoPago: valueRadioButton,
				},
			});	

			setLoading(false);

			await (await stripe).redirectToCheckout({ sessionId: checkoutSession.id });
		} catch (error) {
			console.log("Error al obtener la información para generar el checkout: ", error);
		}
	};

	const handleChangeOpcionPago = ({ target }) => {
		setValueRadioButton(target.value);
	};

	const consultarFolioCompra = async () => {
		try {
			const { folioContrato } = await getFolioContratoCompra();


			setFolioContrato(folioContrato);

			setContextValue({
				...contextValue,
				compra: {
					...contextValue.compra,
					folioContrato,
				},
			});
		} catch (error) {
			console.log("error al obtener el folio de contrato: ", error);
		}
	};

	useEffect(() => {
		if (folioContrato === "") {
			consultarFolioCompra();
		}
	}, []);

	useEffect(() => {
		const { contado, msi, credito } = preciosXCodigo;

		const auxOpcionesPago = [
			contado !== undefined && { value: "contado", label: "De Contado" },
			msi !== undefined && { value: "msi", label: "Meses sin intereses" },
			credito !== undefined && { value: "credito", label: "A Crédito" },
		].filter(esValido);

		setOpcionesPago(auxOpcionesPago);
	}, [preciosXCodigo]);

	return (
		<Col sm={12} lg className="">
			<MUIFormControl>
				<FormLabel id="opciones-pago">Elige tu modalidad de pago</FormLabel>

				<RadioGroup aria-labelledby="opciones-pago" name="opcionPago" row value={valueRadioButton} onChange={handleChangeOpcionPago}>
					{opcionesPago.map(({ value, label }, idx) => (
						(!compraExterna || (compraExterna && value !== 'msi')) && 
						<FormControlLabel key={idx} value={value} control={<Radio />} label={label} />
					))}
				</RadioGroup>

				{valueRadioButton === "" ? (
					<Typography variant="caption" gutterBottom className="text-danger">
						Elige una opción de pago
					</Typography>
				) : null}
			</MUIFormControl>

			<Form onSubmit={handleSubmitValidacionCodigo}>
				<Typography variant="overline" gutterBottom>
					Ingresa aquí tu código de descuento
				</Typography>

				<InputGroup className="mb-3">
					<FormControl className="mb-2 txtCodigoDescuento" name="codigoDescuento" onChange={handleInputChange} placeholder="Código de descuento" value={inputValue} disabled={codigoAplicado} />

					<Button className="mb-2" type="submit" variant="warning">
						Aplicar código
					</Button>
				</InputGroup>

				<div className="d-grid gap-2">
					<Button onClick={handlePagarAhora} size="large" variant="orange-gc">
						Pagar ahora {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
					</Button>
				</div>
			</Form>
		</Col>
	);
};

export default FormCodigoDescuento;
