import { Notificacion } from "@components/general/Alertas/Alertas";
import DatosFacturacion from "@components/usuario/MisPagos/components/facturacion/DatosFacturacion";
import UserContext from "@context/User/UserContext";
import useWindowResize from "@customHooks/useWindowResize";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import { enviarComprobanteCompraCertificacionMOS } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { enviarCorreoFacturacion, retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import { registrarNuevoPagoCertificacionMos, validarDatosPago } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { addCertificacionMos } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import Loader from "@helpers/loader/loader";
import ThumbUpSvg from "@helpers/resources/ThumbUpSvg";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Animated } from "react-animated-css";
import { Button, Col, Container, Image, Navbar, Row } from "react-bootstrap";

const CompraCertificacionRealizada = ({ history }) => {
	const {
		size: { width },
	} = useWindowResize();

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { folioContrato, correo, nombreCompleto, apellidos, idCodigoRegion } = usuarioLogeado;

	const [paymentIntent, setPaymentIntent] = useState({});

	const [paymentMethod, setPaymentMethod] = useState({});

	const [facturacionEnviada, setFacturacionEnviada] = useState(false);

	const [formValuesFacturacion, setFormValuesFacturacion] = useState({
		usoCfdi: "",
		claveCfdi: "",
		constanciaSituacionFiscalData: "",
	});

	const [pagoRealizado, setPagoRealizado] = useState(false);

	const [showSpinner, setShowSpinner] = useState(false);

	const [showLoader, setShowLoader] = useState(true);

	const handleButtonClick = async () => {
		let montoCobro = "",
			tipoTarjeta = "";

		const { amount } = paymentIntent;

		const {
			card: { funding },
		} = paymentMethod;

		montoCobro = amount / 100;

		tipoTarjeta = funding === "credit" ? "Tarjeta de crédito" : "Tarjeta de débito";

		await enviarCorreoFacturacion({
			pdfData: formValuesFacturacion.constanciaSituacionFiscalData,
			folioContrato: contextValue.folioContrato,
			cfdi: {
				clave: formValuesFacturacion.claveCfdi,
				descripcion: formValuesFacturacion.usoCfdi,
			},
			metodoPago: tipoTarjeta,
			nombreCliente: `${nombreCompleto} ${apellidos}`,
			correoCliente: correo,
			montoCobro: numberCommaSeparator(montoCobro),
		});

		Notificacion("success", "Solicitud de facturación enviada con éxito");

		setFacturacionEnviada(true);
	};

	const handleBackToDashboard = () => {
		localStorage.removeItem("comprobanteEnviado");

		history.replace(dashboardRoutesPaths.dashboard);
	};

	useEffect(() => {
		const validarExistenciaPago = async () => {
			try {
				const {
					stripeSession: { payment_intent },
				} = contextValue;

				const { paymentIntent, paymentMethod } = await retrievePaymentIntent(payment_intent);

				setPaymentIntent(paymentIntent);

				setPaymentMethod(paymentMethod);

				const { data } = await validarDatosPago(folioContrato, idCodigoRegion);

				const { pagosContrato } = data;

				if (pagosContrato.find((pago) => pago.referenciaPago === paymentIntent.id) !== undefined) {
					return history.replace(dashboardRoutesPaths.dashboard);
				}

				setPagoRealizado(true);
			} catch (error) {
				console.log("Error al obtener el historial de pagos: ", error);
			}
		};

		validarExistenciaPago();
	}, []);
	

	useEffect(() => {
		if (pagoRealizado) {
			const registrarPagoSai = async () => {
				try {
					const { id, amount } = paymentIntent;

					const {
						card: { last4, funding },
					} = paymentMethod;

					const idMedioPago = funding === "credit" ? 7 : 9;

					const fechaActual = moment().format("yyyy-MM-DD|HH:mm");

					const [fechaPago, horaPago] = fechaActual.split("|");

					await registrarNuevoPagoCertificacionMos(
						{
							fechaPago,
							horaPago,
							montoPago: amount / 100,
							referenciaPago: id,
							comentarioPago: "Compra de Certificación MOS adicional",
							idMedioPago,
						},
						folioContrato,
						idCodigoRegion
					);

					const comprobanteEnviado = await enviarComprobanteCompraCertificacionMOS({
						titular: `${nombreCompleto} ${apellidos}`,
						paymentIntentId: id,
						folioContrato,
						montoCobro: amount / 100,
						numeroTarjeta: `Tarjeta con terminación ${last4}`,
						description: `Compra de Certificación MOS adicional - Folio ${folioContrato}`,
						correo,
					});
	
					localStorage.setItem("comprobanteEnviado", comprobanteEnviado);
	
					await addCertificacionMos({
						folioContrato,
						cantidadCertificaciones: 1,
					});
	
					setShowLoader(false);

					setPagoRealizado(false);
				} catch (error) {
					console.log("error al validar el pago: ", error);
				}
			};

			registrarPagoSai();
		}
	}, [pagoRealizado]);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
					<Navbar expand="lg" data-bs-theme="dark" bg="dark" className="bg-dark-custom">
						<Container fluid className="justify-content-center justify-content-md-between">
							<Navbar.Brand href="#home">
								<div className="m-1">
									<img
										className="pointer"
										// onClick={() => handleChangeComponent("home")}
										src={require(`@assets/images/click.png`)}
										alt="Logo Global Click"
										style={{ width: `${width > 992 ? "10vw" : "20vw"}` }}
									/>
								</div>
							</Navbar.Brand>
						</Container>
					</Navbar>

					<Container fluid className="py-30 bg-main-container">
						<Row>
							<Col>
								<Container fluid className="bg-light-dark">
									<Row className="text-white">
										<Col>
											<Container fluid className="p-0 p-lg my-3">
												<Row>
													<Col className="text-center">
														<ThumbUpSvg />
													</Col>
												</Row>

												<Row>
													<Col className="text-center">
														<Container>
															<Row>
																<Col>
																	<h3 className="text-success text-uppercase">¡Gracias por tu pago!</h3>

																	<p className="text-center">
																		Tu Certificación MOS ya se encuentra disponible para ser asignada, visita la pestaña "Certificaciones adquiridas" para ver más información.
																	</p>

																	<p className="text-center">
																		Recibirás un correo electrónico de <b>notificaciones@globalclickmexico.com</b> con tu comprobante de pago.
																	</p>
																</Col>
															</Row>

															{!facturacionEnviada && (
																<>
																	<DatosFacturacion formValues={formValuesFacturacion} setFormValues={setFormValuesFacturacion} />

																	{formValuesFacturacion.facturacionSolicitada && (
																		<Button variant="success" onClick={handleButtonClick} disabled={facturacionEnviada} className="mt-3">
																			Solicitar facturación {showSpinner && <FontAwesomeIcon icon={faSpinner} spin />}
																		</Button>
																	)}
																</>
															)}

															<Row className="mt-3">
																<Col>
																	<Button variant="primary" onClick={handleBackToDashboard}>
																		Regresar a la plataforma
																	</Button>
																</Col>
															</Row>
														</Container>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Animated>
			)}
		</>
	);
};

export default CompraCertificacionRealizada;
