const multiViewPanelAdministracionPaths = {
  dashboardAulaVirtual: "dashboardAulaVirtual",
  dashboardBancoPreguntas: "dashboardBancoPreguntas",
  dashboardGestionContenidoCursos: "dashboardGestionContenidoCursos",
  dashboardUsuarios: "dashboardUsuarios",
  dashboardConsejosRapidos: "dashboardConsejosRapidos",
  dashboardLicencias: "dashboardLicencias",
  dashboardNotificaciones: "dashboardNotificaciones",
  dashboardRutasAprendizaje: "dashboardRutasAprendizaje",
  dashboardForoDeAyuda: "dashboardForoDeAyuda",
  dashboardLeccionesEmpresariales: "dashboardLeccionesEmpresariales",
  dashboardRegistroUsuarioInvitado: "dashboardRegistroUsuarioInvitado",
  dashboardApariencia: "dashboardApariencia",
  dashboardPlantillasTrabajo: "dashboardPlantillasTrabajo",
  dashboardRegistroNuevoUsuario: "dashboardRegistroNuevoUsuario",
  dashboardEstadisticasPlataforma: "dashboardEstadisticasPlataforma",
  dashboardAgendasAsesorias: 'dashboardAgendasAsesorias',
  dashboardSeguridad: 'dashboardSeguridad',
  dashboardAyudaAdministradores: 'dashboardAyudaAdministradores',
  documentacionApi: 'apiDocumentation'
};

export default multiViewPanelAdministracionPaths;
