import React from "react";
import { Panel } from "primereact/panel";
import { Timeline } from "primereact/timeline";
import { Alert, Card, Col, Row, Table } from "react-bootstrap";
import { HiDesktopComputer, HiOutlineClipboardList } from "react-icons/hi";
import { useEffect } from "react";
import { getCertificadosMos } from "@helpers/httpRequests/httpRequestMOS/httpRequestMOS";
import { useState } from "react";

import { validarDatosPago } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { Checkbox } from "@mui/material";
// import { Checkbox } from "primereact/checkbox";

const Certificacion = ({ idCodigoRegion, folioContrato, events, customizedMarker, customizedContent, tab, idPerfil, setBtnCertificadoDisabled, rol, usuarioTitular, derechoMos }) => {
	const [cursosCertificados, setCursosCertificados] = useState(false);

	const [certificados, setCertificados] = useState([]);

	const [excelAplicado, setExcelAplicado] = useState(false);

	const [excelIntermedio, setExcelIntermedio] = useState(false);

	const [pagoCubierto, setPagoCubierto] = useState(0); // 1= 'Pago cubierto'/0 = 'No aplica/ 2 = Pago no cubierto'

	const [datosPago, setDatosPago] = useState({});
	//	state para sacar el promedio del pago

	const [pagoCredito, setPagoCredito] = useState({
		aplica: null,
		costoTotal: 0,
		pagado: 0,
		deudaSaldada: null
	});

	useEffect(() => {
		const validarPago = async () => {
			try {
				const { data: datosPago1 } = await validarDatosPago(parseInt(folioContrato), idCodigoRegion);

				setDatosPago(datosPago1);

				const { costoTotal, pagado, amortizacionesTotales, pagosContrato } = datosPago1;


				if (amortizacionesTotales > 1) {
					setPagoCredito({
						...pagoCredito,
						aplica: true,
						costoTotal,
						pagado,
					});
				}

				
				pagosContrato.map((amortizacion) => {
					if (amortizacion.conceptoPago === "PAGO POR FINIQUITO"){
						setPagoCredito({
							...pagoCredito,
							deudaSaldada: true
						})
					}
				})

			} catch (error) {
				console.log("Ocurrio un error al consumir la informacion de la api: ", error);
			}
		};

		validarPago();

		const verificarCuenta = async () => {
			try {
				const { data, code } = await getCertificadosMos(idPerfil);

				if (Array.isArray(data)) {
					setCertificados(data);

					setCursosCertificados(true);
				} else {
					setCertificados([data]);
					// console.log(data);
				}
			} catch (error) {
				console.log(error);
				setCertificados([]);
			}
		};

		verificarCuenta();
	}, []);

	useEffect(() => {
		//VALIDACION PARA EL PORCENTAJE DEL PAGO
		if (pagoCredito.aplica) {
			const porcentajeCredito = (pagoCredito.pagado * 100) / pagoCredito.costoTotal;

			if (porcentajeCredito >= 85) {
				setPagoCubierto(1);
			} else {
				setPagoCubierto(2);
			}
		} else {
			const { costoTotal, pagado } = datosPago;

			if (costoTotal === pagado) {
				setPagoCubierto(1);
			}
		}
	}, [datosPago]);

	useEffect(() => {
		certificados.map((certificado) => {
			if (certificado.curso === "EI16" || certificado.curso === "EI23") {
				setExcelIntermedio(true);
			}
			if (certificado.curso === "EA16") {
				setExcelAplicado(true);
			}
		});
	}, [certificados]);

	useEffect(() => {
		if (tab === "info") {
			if (pagoCredito.aplica) {
				if (excelAplicado && excelIntermedio) {
					if (pagoCubierto === "Pago cubierto"  || pagoCredito.deudaSaldada) {
						//setRequisitosCubiertos
						setBtnCertificadoDisabled(false);
					}
				} else {
					// Notificacion("info", "Aún no cubres todos los requisitos para agendar tu examen de certificación")
					setBtnCertificadoDisabled(true);
				}
			} else {
				if (excelAplicado && excelIntermedio) {
					if (pagoCubierto === "Pago cubierto"  || pagoCredito.deudaSaldada) {
						//setRequisitosCubiertos
						setBtnCertificadoDisabled(false);
					}
				} else {
					setBtnCertificadoDisabled(true);

					// Notificacion("info", "Aún no cubres todos los requisitos para agendar tu examen de certificación")
				}
			}
		}
	}, [tab]);

	return (
		<>
			<Row className="mt-5">
				<Col className="col-12 col-lg-6 col-6">
					<iframe
						className="ratio ratio-16x9"
						width="100%"
						height="100%"
						src="https://player.vimeo.com/video/805643035?h=c2b119362d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="Certificación MOS video"
					/>
				</Col>

				<Col className="col-12 col-lg-6 col-6">
					<Card>
						<Card.Header>
							<h4>¿Qué es la certificación MOS?</h4>
						</Card.Header>

						<Card.Body>
							<p>
								El objetivo de estas certificaciones Microsoft Office Specialist (MOS) es la acreditación del conocimiento, las competencias y las habilidades en el manejo de las diferentes
								herramientas de Microsoft Office, en sus versiones Microsoft Office 2016 y Microsoft Office 2019/365.
							</p>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="mt-5 mb-5">
				<Col className="col-12 col-lg-7 mt-4 mt-lg-0">
					<h4>
						<HiOutlineClipboardList /> Requisitos
					</h4>

					<Table borderless>
						<thead>
							<tr>
								<th>Indispensable contar con los siguientes requisitos:</th>
								<th></th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							<tr>
								<td>Excel Intermedio</td>
								<td></td>
								<td>
									<Checkbox checked={excelIntermedio ? true : false} />
								</td>
							</tr>

							<tr>
								<td>Excel Avanzado</td>
								<td></td>
								<td>
									<Checkbox checked={excelAplicado ? true : false} />
								</td>
							</tr>

							<tr>
								<td>En el caso de haber adquirido la licencia con el método de pago a crédito, deberás estar al corriente y haber liquidado al menos el 85% del total de tu licencia.</td>
								<td></td>
								<td>
									{pagoCredito ? (
										<span className={pagoCubierto === 1 ? "text-success" : "text-danger"}>{pagoCubierto === 1 ? "Pago cubierto" : "Pago no cubierto"}</span>
									) : (
										<span className={pagoCubierto === 0 && "text-success"}>{pagoCubierto}</span>
									)}
								</td>
							</tr>

							<tr>
								<td>Lecciones del apartado propedéutico terminadas al 100%</td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				</Col>

				<Col className="col-12 col-lg-5 mt-4 mt-lg-0">
					<h4>
						<HiDesktopComputer /> Requisitos del sistema
					</h4>

					<ul className="mt-4">
						<li>Contar con sistema operativo Windows (7, 10, 11)</li>
						<li>Conexión estable a internet</li>
					</ul>
				</Col>
			</Row>

			<Row>
				<Col className="text-center">
					<Alert variant="danger">En caso de no presentarse al examen se contará un intento fallido y será necesario reagendar la segunda Oportunidad</Alert>
				</Col>
			</Row>

			<Row className="mt-5 mb-5">
				<Col>
					<Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
				</Col>
			</Row>
		</>
	);
};

export default Certificacion;
