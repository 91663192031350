import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const getUsuarios = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const usuarios = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getUsuarios}`,
				method: "GET",
			});

			resolve(usuarios);
		} catch (error) {
			// console.log("Error obteniendo los usuarios: ", error);

			reject(error);
		}
	});
};

export const getUsuario = (correo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: datosUsuario } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getUsuarioRecuperacionContrasenia}`,
				method: "POST",
				body: JSON.stringify({ correo }),
			});

			resolve(datosUsuario);
		} catch (error) {
			// console.log("Error al obtener el usuario por correo: ", error);

			reject(error);
		}
	});
};

export const getUsuarioByFolio = (folioContrato, idRegion) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: datosUsuario } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getUsuarioByFolio}/${ folioContrato }/${idRegion}`,
				method: "POST"
			});

			if (datosUsuario.message && datosUsuario.message === 'No existe ningún usuario con este correo') {
				return reject(null);
			}

			resolve(datosUsuario);
		} catch (error) {
			reject(error);
		}
	});
};

export const getDatosUsuario = (infoUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const datosUsuario = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getDatosUsuario}`,
				method: "POST",
				body: JSON.stringify(infoUsuario),
			});

			resolve(datosUsuario);
		} catch (error) {
			// console.log("Error obteniendo datos del usuario logeado: ", error);

			reject(error);
		}
	});
};

export const updateIntereses = ({ intereses, idPersona }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const interesesActualizados = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateIntereses}`,
				method: "POST",
				body: JSON.stringify({ idPersona, intereses }),
			});

			resolve(interesesActualizados);
		} catch (error) {
			// console.log("Error al actualizar los  intereses del usuario: ", error);

			reject(error);
		}
	});
};

export const updateContrasenia = (credenciales) => {
	return new Promise(async (resolve, reject) => {
		try {
			const updatedPassword = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateContrasenia}`,
				method: "POST",
				body: JSON.stringify(credenciales),
			});

			resolve(updatedPassword);
		} catch (error) {
			// console.log("Error al actualizar la contraseña: ", error);

			reject(error);
		}
	});
};

export const updatePerfil = (datosPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const profile = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updatePerfil}`,
				method: "POST",
				body: JSON.stringify(datosPerfil),
			});

			resolve(profile);
		} catch (error) {
			// console.log("Error al actualizar perfil: ", error);

			reject(error);
		}
	});
};

export const updateFechaRegistro = (fechaRegistro) => {
	return new Promise(async (resolve, reject) => {
		try {
			const fechaRegistroActualizada = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateFechaRegistro}`,
				method: "POST",
				body: JSON.stringify(fechaRegistro),
			});

			resolve(fechaRegistroActualizada);
		} catch (error) {
			// console.log("Error al actualizar perfil: ", error);

			reject(error);
		}
	});
};

export const addNipPerfil = ({ idPerfil, nip }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: nipAgregado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.addNipPerfil}`,
				method: "POST",
				body: JSON.stringify({
					idPerfil,
					nip,
				}),
			});

			resolve(nipAgregado);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteNipPerfil = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: nipEliminado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.deleteNipPerfil}/${idPerfil}`,
				method: "POST",
			});

			resolve(nipEliminado);
		} catch (error) {
			reject(error);
		}
	});
};

export const validateNipPerfil = ({ idPerfil, nip }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: nipValido } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateNipPerfil}`,
				method: "POST",
				body: JSON.stringify({
					idPerfil,
					nip,
				}),
			});

			resolve(nipValido);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateNipPerfil = ({ idPerfil, nip }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: nipActualizado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateNipPerfil}`,
				method: "POST",
				body: JSON.stringify({
					idPerfil,
					nip,
				}),
			});

			resolve(nipActualizado);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateStatusTour = (idPersona) => {
	return new Promise(async (resolve, reject) => {
		try {
			const estatusActualizado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateStatusTour}/${idPersona}`,
				method: "POST",
			});

			resolve(estatusActualizado);
		} catch (error) {
			// console.log("Algo ocurrió al actualizar el estatus del tour: ", error);

			reject(error);
		}
	});
};

export const updateNombreUsuario = (credentials) => {
	return new Promise(async (resolve, reject) => {
		try {
			const profile = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateNombreUsuario}`,
				method: "POST",
				body: JSON.stringify(credentials),
			});

			resolve(profile);
		} catch (error) {
			// console.log("Error al actualizar perfil: ", error);

			reject(error);
		}
	});
};

export const validateCredenciales = ({ nombreUsuario, contrasenia }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: validacionCredenciales } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateCredenciales}`,
				method: "POST",
				body: JSON.stringify({ nombreUsuario, contrasenia }),
			});

			resolve(validacionCredenciales);
		} catch (error) {
			// console.log("Error al validar las credenciales: ", error);

			reject(error);
		}
	});
};

export const validateExistenciaFolioContrato = (folioContrato) => {
	return new Promise(async (resolve, reject) => {
		try {
			const existenciaFolio = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateExistenciaFolioContrato}/${folioContrato}`,
				method: "POST",
			});

			resolve(existenciaFolio);
		} catch (error) {
			// console.log("Error al validar la existencia del folio de contrato: ", error);

			reject(error);
		}
	});
};

export const validateExistenciaNombreUsuario = (nombreUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const existenciaNombreUsuario = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateExistenciaNombreUsuario}`,
				method: "POST",
				body: JSON.stringify({ nombreUsuario }),
			});

			resolve(existenciaNombreUsuario);
		} catch (error) {
			// console.log("Error al validar la existencia del nombre de usuario: ", error);

			reject(error);
		}
	});
};

export const validateExistenciaAlias = (alias) => {
	return new Promise(async (resolve, reject) => {
		try {
			const existenciaNombreUsuario = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateExistenciaAlias}`,
				method: "POST",
				body: JSON.stringify({ alias }),
			});

			resolve(existenciaNombreUsuario);
		} catch (error) {
			// console.log("Error al validar la existencia del alias: ", error);

			reject(error);
		}
	});
};

export const validateExistenciaCURP = (curp) => {
	return new Promise(async (resolve, reject) => {
		try {
			const existenciaCurp = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateExistenciaCURP}`,
				method: "POST",
				body: JSON.stringify({ curp }),
			});

			resolve(existenciaCurp);
		} catch (error) {
			// console.log("Error al validar la existencia del CURP: ", error);

			reject(error);
		}
	});
};

export const validateExistenciaCorreo = (correo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const existenciaCorreo = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.validateExistenciaCorreo}`,
				method: "POST",
				body: JSON.stringify({ correo }),
			});

			console.log("Existencia correo: ", existenciaCorreo);

			resolve(existenciaCorreo);
		} catch (error) {
			// console.log("Error al validar la existencia del correo: ", error);

			reject(error);
		}
	});
};

export const validateTokenExpiry = (token) => {
	return new Promise(async (resolve, reject) => {
		try {
			const tokenValido = httpRequest({
				url: `${endpointsPaths.tokens}/${httpRequestsPaths.tokens.validateTokenExpiry}/${token}`,
				method: "POST",
			});

			resolve(tokenValido);
		} catch (error) {
			// console.log("Error al validar la fecha de expiración del token: ", error);

			reject(error);
		}
	});
};

export const recoverCredenciales = (correo) => {
	return new Promise(async (resolve, reject) => {
		try {
			const credenciales = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.recoverCredenciales}`,
				method: "POST",
				body: JSON.stringify({ correo }),
			});

			resolve(credenciales);
		} catch (error) {
			// console.log("Error al recuperar las credenciales de acceso: ", error);

			reject(error);
		}
	});
};

export const updateTiempoEnPlataforma = (infoPersona) => {
	return new Promise(async (resolve, reject) => {
		try {
			const tiempoPlataforma = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateTiempoPlataforma}`,
				method: "POST",
				body: JSON.stringify(infoPersona),
			});

			resolve(tiempoPlataforma);
		} catch (error) {
			// console.log("Error validando el correo: ", error);

			reject(error);
		}
	});
};

export const getTiempoPlataforma = (idPersona) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: getTiempo } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getTiempoPlataforma}/${idPersona}`,
				method: "POST",
			});
			resolve(getTiempo);
		} catch (error) {
			// console.log("ERROR al obtener el tiempo en plataforma", error);
			reject(error);
		}
	});
};

export const updateFechaUltimoAcceso = (infoPersona) => {
	return new Promise(async (resolve, reject) => {
		try {
			const idActualizado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateFechaUltimoAcceso}`,
				method: "POST",
				body: JSON.stringify(infoPersona),
			});
			resolve(idActualizado);
		} catch (error) {
			// console.log("ERROR al actualizar la fecha de acceso", error);
			reject(error);
		}
	});
};

export const addSegundoPerfilEstudiante = async (informacionSegundoUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const secondUser = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addSegundoPerfilEstudiante}`,
				method: "POST",
				body: JSON.stringify(informacionSegundoUsuario),
			});

			resolve(secondUser);
		} catch (error) {
			reject(error);
		}
	});
};

export const deactivateUsuario = (idCredencial) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: usuarioDesactivado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.deactivateUsuario}/${idCredencial}`,
				method: "POST",
			});

			resolve(usuarioDesactivado);
		} catch (error) {
			// console.log("Error al desactivar el usuario: ", error);

			reject(error);
		}
	});
};

export const reactivateUsuario = (idCredencial) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: usuarioReactivado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.reactivateUsuario}/${idCredencial}`,
				method: "POST",
			});

			resolve(usuarioReactivado);
		} catch (error) {
			// console.log("Error al reactivar el usuario: ", error);

			reject(error);
		}
	});
};

export const removerUsuario = (datosUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: usuarioEliminado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.removerUsuario}`,
				method: "POST",
				body: JSON.stringify(datosUsuario),
			});

			resolve(usuarioEliminado);
		} catch (error) {
			reject(error);
		}
	});
};

export const addExtensionTiempoVigencia = (datosExtension) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: tiempoVigenciaExtendido } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.addExtensionTiempoVigencia}`,
				method: "POST",
				body: JSON.stringify(datosExtension),
			});

			resolve(tiempoVigenciaExtendido);
		} catch (error) {
			// console.log("Error al agregar tiempo de vigencia: ", error);

			reject(error);
		}
	});
};

export const updateFechaExpiracion = (datosUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: fechaModificada } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateFechaExpiracion}`,
				method: "POST",
				body: JSON.stringify(datosUsuario),
			});

			resolve(fechaModificada);
		} catch (error) {
			// console.log("Error al agregar tiempo de vigencia: ", error);

			reject(error);
		}
	});
};

export const updateLicenciaUsuario = (datosLicencias) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateLicenciaUsuario}`,
				method: "POST",
				body: JSON.stringify(datosLicencias),
			});

			resolve(response);
		} catch (error) {
			// console.log("Error al actualizar la licencia y los cursos del usuario: ", error);

			reject(error);
		}
	});
};

export const registrarTokenRegistro = ({ folioContrato, tokenRegistro }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: tokenRegistroGuardado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.registrarTokenRegistro}/${folioContrato}`,
				method: "POST",
				body: JSON.stringify({ tokenRegistro }),
			});

			resolve(tokenRegistroGuardado);
		} catch (error) {
			// console.log("Algo sucedió al registar el token de registro: ", error);

			reject(error);
		}
	});
};

export const getTokenRegistro = (folioContrato) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: tokenRegistro } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getTokenRegistro}/${folioContrato}`,
				method: "POST",
			});

			resolve(tokenRegistro);
		} catch (error) {
			// console.log("Algo sucedió al consultar el token de registro: ", error);

			reject(error);
		}
	});
};

export const getNotificacionesUsuario = (idPerfil) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: notificaciones } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getNotificacionesUsuario}/${idPerfil}`,
				method: "POST",
			});

			resolve(notificaciones);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateNotificacionUsuario = (idNotificacionUsuario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: actualizado } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.updateNotificacionUsuario}/${idNotificacionUsuario}`,
				method: "POST",
			});

			resolve(actualizado);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCertificacionesMosByFolio = (folioContrato) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: certificaciones } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getCertificacionesMosByFolio}/${folioContrato}`,
				method: "POST",
			});

			resolve(certificaciones);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCertificacionesMosByIdCredencial = ({ folioContrato, idCredencial }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: certificaciones } = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.getCertificacionesMosByIdCredencial}/${folioContrato}/${idCredencial}`,
				method: "POST",
			});

			resolve(certificaciones);
		} catch (error) {
			reject(error);
		}
	});
};

export const addCertificacionMos = ({ folioContrato, cantidadCertificaciones }) => {
	return new Promise(async (resolve, reject) => {
		try {
			await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.addCertificacionMos}/${folioContrato}/${cantidadCertificaciones}`,
				method: "POST",
			});

			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
};

export const asignarCertificacionMos = (datosCertificacion) => {
	return new Promise(async (resolve, reject) => {
		try {
			await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.asignarCertificacionMos}`,
				method: "POST",
				body: JSON.stringify(datosCertificacion),
			});

			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
};

export const asignarCertificacionMosEmpresarial = (datosCertificacion) => {
	return new Promise(async (resolve, reject) => {
		try {
			await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.asignarCertificacionMosEmpresarial}`,
				method: "POST",
				body: JSON.stringify(datosCertificacion),
			});

			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
};

export const agregarSegundoIntentoCertificacionMos = ({ idCertificacionMos, fechaAgenda }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const segundoIntentoAgregado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.agregarSegundoIntentoCertificacionMos}`,
				method: "POST",
				body: JSON.stringify({ idCertificacionMos, fechaAgenda }),
			});

			resolve(segundoIntentoAgregado);
		} catch (error) {
			reject(error);
		}
	});
};

export const actualizarIntentoCertificacionMos = ({ idIntentoCertificacionMos, examenRealizado, aprobado }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const intentoActualizado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.actualizarIntentoCertificacionMos}`,
				method: "POST",
				body: JSON.stringify({ idIntentoCertificacionMos, examenRealizado, aprobado }),
			});

			resolve(intentoActualizado);
		} catch (error) {
			reject(error);
		}
	});
};

export const agendarExamenCertificacionMos = ({ folioContrato, idPerfil, fechaAgenda }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const examenAgendado = await httpRequest({
				url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.general.agendarExamenCertificacionMos}`,
				method: "POST",
				body: JSON.stringify({
					folioContrato,
					idPerfil,
					fechaAgenda,
				}),
			});

			resolve(examenAgendado);
		} catch (error) {
			reject(error);
		}
	});
};

export const setDarkMode = ({ idPerfil, darkMode }) => {
	return new Promise(async (resolve, reject) => {
		try {
			await httpRequest({
				url: `${ endpointsPaths.usuarios }/${ httpRequestsPaths.usuarios.general.setDarkMode }/${ idPerfil }`,
				method: 'POST',
				body: JSON.stringify({ darkMode })
			});

			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
};

export const buscarUsuarios = (filtrosBusqueda) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: usuarios } = await httpRequest({
				url: `${ endpointsPaths.usuarios }/${ httpRequestsPaths.usuarios.general.buscarUsuarios }`,
				method: 'POST',
				body: JSON.stringify(filtrosBusqueda)
			});

			resolve(usuarios);
		} catch (error) {
			reject(error);
		}
	});
};

export const renovarLicencia = ({ folioContrato, datosRenovacion, idPersona, idCredencial }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.usuarios }/${ httpRequestsPaths.usuarios.general.renovarLicencia }/${ folioContrato }`,
				method: 'POST',
				body: JSON.stringify({
					...datosRenovacion,
					idPersona,
					idCredencial
				})
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getTemasInteres = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: intereses } = await httpRequest({
				url: `${ endpointsPaths.usuarios }/${ httpRequestsPaths.usuarios.general.getTemasInteres }`,
				method: 'POST'
			});

			resolve(intereses);
		} catch (error) {
			reject(error);
		}
	});
};