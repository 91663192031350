import React, { useEffect, useRef, useState } from "react";
import { Alert, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleForoAyuda from "../Titles/TitleForoAyuda";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCalendarPlus, faFilePdf, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";

import "@assets/css/components/CursoPropedeuticoMos/style.css";

import { Link } from "react-scroll";
import { ModalSweetAlert } from "../Alertas/Alertas";
import AgendarExamen from "./Modales/AgendarExamen";
import { useContext } from "react";
import UserContext from "@context/User/UserContext";
import { descargarMaterialApoyo } from "@helpers/httpRequests/httpRequestMOS/httpRequestMOS";

import CursoPropedeuticoMos from "./Componentes/CursoPropedeuticoMos";
import Certificacion from "./Componentes/Certificacion";
import { Animated } from "react-animated-css";
import useWindowResize from "@customHooks/useWindowResize";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import CertificacionesAdquiridas from "./Componentes/CertificacionesAdquiridas";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { getCertificacionesMosByFolio } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

const CursoPropedeutico = ({ handleScroll, handleScrollToTop, showScrollButton }) => {
	const joyrideRef = useRef(null);

	const [tourActivo, setTourActivo] = useState(false);

	const [runTour, setRunTour] = useState(false);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".question-propedeutico",
			content: "Bienvenido al apartado de propedéutico MOS, aquí te proporcionamos un entorno de práctica para prepararte para tu examen de certificación MOS.",
			disableBeacon: true,
			placement: "left-start",
		},

		{
			target: ".lecciones-mos",
			content: "A tu izquierda te hemos listado tanto las lecciones como un ejercicio para practicar junto con un archivo descargable donde podrás llevar un mejor control a la hora de practicar.",
			disableBeacon: true,
			placement: "left-start",
		},

		{
			target: ".visor-mos",
			content: "Aquí se mostrarán las lecciones interactivas.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".end-propedeutico",
			content: "Es hora de que practiques, no esperes más, ¡buena suerte!.",
			disableBeacon: true,
			placement: "left-start",
		},
	]);

	const {
		size: { width },
	} = useWindowResize();

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { correo, idPerfil, rol, usuarioTitular, derechoMos, folioContrato, idCodigoRegion } = usuarioLogeado;

	const [checked, setChecked] = useState(false);

	const [rutaLeccion, setRutaLeccion] = useState({
		pathBase: "https://virtual.globalclickmexico.com/lecciones/MOS",
	});

	const [rutaProyecto, setRutaProyecto] = useState({
		pathBase: "https://virtual.globalclickmexico.com/lecciones/MOS",
	});

	const [codigo, setCodigo] = useState(null);

	const [urlVideo, setUrlVideo] = useState(null);

	const [key, setKey] = useState("propedeutico");

	const [btnCertificadoDisabled, setBtnCertificadoDisabled] = useState(false);

	const events = [
		{
			status: "Manual del simulador",
			icon: "pi pi-download",
			color: "#00152a",
		},
		{ status: "Proceso de examen", icon: "pi pi-download", color: "#00152a" },
		{
			status: "Cuenta Certiport",
			icon: "pi pi-user",
			color: "#00152a",
			image: "game-controller.jpg",
		},
		{ status: "Agendar Examen", icon: "pi pi-calendar-plus", color: "#00152a" },
	];

	const handleStartTour = () => {
		setRunTour(!runTour);
	};

	const customizedMarker = (item) => {
		return (
			<span style={{ width: "2rem", height: "2rem", backgroundColor: item.color }} className="d-flex align-items-center justify-content-center text-white rounded-circle z-1 shadow-1 icon-timeline">
				<i className={item.icon}></i>
			</span>
		);
	};

	const materialApoyo = async () => {
		try {
			const { data } = await descargarMaterialApoyo();

			const dataBinaria = new Uint8Array(data.data);

			const datos = new Blob([dataBinaria]);

			const link = document.createElement("a");

			link.href = window.URL.createObjectURL(datos);

			const fileName = "Proyectos_en_excel.pdf";

			link.download = fileName;

			link.click();
		} catch (error) {
			console.log(error);
		}
	};

	const verLeccionDeApoyo = (nombreLeccion, codigoLeccion) => {
		const { pathBase } = rutaLeccion;
		setCodigo(codigoLeccion);
		setUrlVideo(`${pathBase}/${codigoLeccion}/index.html`);
	};

	const verProyecto = (nombreProyecto, codigoProyecto) => {
		const { pathBase } = rutaProyecto;

		setCodigo(codigoProyecto);

		setUrlVideo(`${pathBase}/${codigoProyecto}/index.html`);
	};

	//*CONTENIDO PARA EL COMPONENTE TIMELINE
	const customizedContent = (item) => {
		return (
			<Card>
				<Card.Header>
					<h5>{item.status}</h5>
				</Card.Header>
				<Card.Body>
					{item.status === "Manual del simulador" && (
						<>
							<p>
								Como primer paso para agendar tu examen, te prepararemos en un ambiente de simulación, en el que tendrás 4 intentos para prepararte y te sientas seguro al momento de presentar la
								certificación oficial, a continuación podrás descargar el manual para acceder al ambiente de simulación.
							</p>
							{/* <Button disabled={btnCertificadoDisabled} onClick={descargarManualSimulador} className="p-button-warning btn-responsive">
								<FontAwesomeIcon className="mx-2" icon={faFilePdf} /> Descargar PDF
							</Button> */}
							<a
								style={{ textDecoration: "none" }}
								disabled={btnCertificadoDisabled}
								href="https://globalclickmexico.com/item/Proceso_Gmetrix_Office.pdf"
								target="_blank"
								rel="noreferrer"
								className="p-button-warning p-button btn-responsive">
								<FontAwesomeIcon className="mx-2" icon={faFilePdf} /> Descargar PDF
							</a>
						</>
					)}

					{item.status === "Proceso de examen" && (
						<>
							<p>Luego de acceder al ambiente de simulación es necesario seguir el siguiente proceso para el registro al examen de certificación.</p>
							{/* <Button disabled={btnCertificadoDisabled} label="Descargar PDF" onClick={descargarProcesoExamen} className="p-button p-button-warning  btn-responsive">
								<FontAwesomeIcon className="mx-2" icon={faFilePdf} />
							</Button> */}
							<a
								style={{ textDecoration: "none" }}
								disabled={btnCertificadoDisabled}
								label="Descargar PDF"
								href="https://globalclickmexico.com/item/Proceso_Examen.pdf"
								target="_blank"
								rel="noreferrer"
								className="p-button p-button-warning  btn-responsive">
								<FontAwesomeIcon className="mx-2" icon={faFilePdf} /> Descargar PDF
							</a>
						</>
					)}

					{item.status === "Cuenta Certiport" && (
						<>
							{item.image && <img src={`${require("@assets/images/certiport-logo.png")}`} alt={item.name} width={200} className="shadow-1 mb-3" />}
							<p>Es necesaria que tengas una cuenta activa en Certiport ya que desde aquí se realizará el examen de certificación una vez completes estos pasos y agendes tu cita.</p>
							<p>Si aun no tienes tu cuenta, da click en el siguiente enlace para crear tu cuenta.</p>

							<Alert variant="info">
								<small>Dentro del formulario de registro, llena todos los campos a excepción del campo <b>'Student Number'</b>.</small>
								<small><br/> Al agendar el exámen de certificación, nos pondremos en contacto vía <b>WhatsApp</b> para proporcionarte tus códigos de acceso.</small>
							</Alert>

							<Button
								disabled={btnCertificadoDisabled}
								label="Crear cuenta"
								onClick={() => window.open("https://www.certiport.com/portal/Pages/Registration.aspx?defaultlang=ESM")}
								className="p-button p-button-warning  btn-responsive">
								<FontAwesomeIcon className="mx-2" icon={faUserPlus} />
							</Button>
						</>
					)}

					{item.status === "Agendar Examen" && (
						<>
							<p>
								Este es el último y más importante de todos los pasos, es necesario que agendes con nosotros tu examen de certificación, para ello da click en el botón y llena el formulario de
								registro.
							</p>
							{derechoMos === 1 ? (
								btnCertificadoDisabled ? (
									<Alert variant="danger">
										<h6>Aún no cuentas con lo necesario para agendar</h6>
									</Alert>
								) : (
									<Alert variant="success">
										<h6>Tienes todo lo necesario para agendar tu examen</h6>
									</Alert>
								)
							) : (
								<Alert variant="danger">
									<h6>Debes tener contratada tu Certificación MOS para agendar tu examen.</h6>
								</Alert>
							)}

							{derechoMos === 1 && (
								<Button disabled={btnCertificadoDisabled ? true : false} label="Agendar" onClick={handleAgendarExamen} className="p-button-warning btn-responsive">
									<FontAwesomeIcon className="mx-2 " icon={faCalendarPlus} />
								</Button>
							)}

							{/* <Button label="Agendar" onClick={handleAgendarExamen} className="p-button-warning btn-responsive">
								<FontAwesomeIcon className="mx-2 " icon={faCalendarPlus} />
							</Button> */}
						</>
					)}
				</Card.Body>
			</Card>
		);
	};

	const breadcrumbItemsInitialState = [{ title: "Curso propedéutico", onClick: () => {} }];

	//*Modal para agendar el examen de certificacion
	const handleAgendarExamen = async ({ currentTarget }) => {
		try {
			const { certificacionesAsignadas } = await getCertificacionesMosByFolio(folioContrato);

			if (certificacionesAsignadas.length === 0) {
				return ModalSweetAlert({
					title: "No tienes Certificaciones MOS asignadas",
					html: <p>Asígnala desde la pestaña "Certificaciones adquiridas"</p>,
					icon: "info",
					showCloseButton: true,
					confirmButtonColor: '#0d6efd',
					confirmButtonText: 'Entendido'
				});
			}
			
			await ModalSweetAlert({
				title: "Agendar examen",
				html: <AgendarExamen idPerfil={idPerfil} rol={rol} correo={correo} folioContrato={folioContrato} />,
				showConfirmButton: false,
				showCloseButton: true,
				// width: "1800",
			});
		} catch (error) {
			console.log("error al consultar las certificaciones asignadas: ", error);
		}

	};

	useEffect(() => {
		handleScrollToTop();
	}, []);

	return (
		<>
			{/* //*COMPONENTE ScrollToTop */}
			{showScrollButton && (
				<Animated animationIn="fadeIn" isVisible={true} style={{ zIndex: "9999", position: "absolute" }}>
					<div className="scroll-top">
						<span
							className={`rounded-circle scrollTop text-center d-flex`}
							style={{
								height: "50px",
								width: "50px",
								fontSize: "2rem",
								backgroundColor: "#F59E0B",
								position: "fixed",
								bottom: "70px",
								right: "40px",
							}}>
							<Link className="pointer" activeClass="active" to="scrollTopElement" spy={true} smooth={true} duration={800} containerId="scrollTop" style={{ color: "black", width: "100%" }}>
								<FontAwesomeIcon icon={faArrowUp} />
							</Link>
						</span>
					</div>
				</Animated>
			)}
			<Container fluid style={{ margin: "0" }}>
				<Joyride
					locale={spanishOptionsJoyRide}
					ref={joyrideRef}
					steps={tourSteps}
					run={runTour}
					continuous={true}
					showSkipButton
					hideCloseButton
					disableOverlayClose={true}
					disableCloseOnEsc={false}
					disableScrolling={false}
					scrollToFirstStep={true}
					scrollOffset={100}
					callback={(data) => {
						if (data.status === "finished") {
							setRunTour(false);
						} else if (data.action === "skip") {
							setRunTour(false);
						}
					}}
					styles={{
						options: {
							primaryColor: "#ff9900",
							textColor: "#00152A",
							width: 900,
							zIndex: 1000,
						},
					}}
				/>
				<Row>
					<Col>
						<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
					</Col>
				</Row>

				<Row className="fondo-mos">
					<Col className="fondo-propedeutico">
						<Row>
							<Col className="p-4">
								<TitleForoAyuda title="Curso Propedéutico MOS" />
							</Col>
						</Row>
					</Col>
				</Row>

				<Tabs className="test mt-4" defaultActiveKey="propedeutico" id="uncontrolled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
					{/* //*TAB PARA EL CURSO PROPEDEUTICO */}
					<Tab eventKey="propedeutico" title={<h5>Curso Propedéutico</h5>}>
						<CursoPropedeuticoMos
							handleStartTour={handleStartTour}
							width={width}
							rol={rol}
							usuarioTitular={usuarioTitular}
							derechoMos={derechoMos}
							codigo={codigo}
							setCodigo={setCodigo}
							tab={key}
							urlVideo={urlVideo}
							setUrlVideo={setUrlVideo}
							verLeccionDeApoyo={verLeccionDeApoyo}
							materialApoyo={materialApoyo}
							verProyecto={verProyecto}
						/>
					</Tab>

					{/* //*TAB PARA LA INFORMACION DE LA CERTIFICACION MOS  */}
					<Tab eventKey="info" title={<h5>Certificación MOS</h5>}>
						<Certificacion
							idCodigoRegion={idCodigoRegion}
							folioContrato={folioContrato}
							width={width}
							rol={rol}
							usuarioTitular={usuarioTitular}
							derechoMos={derechoMos}
							className="btn-responsive"
							setBtnCertificadoDisabled={setBtnCertificadoDisabled}
							idPerfil={idPerfil}
							tab={key}
							events={events}
							customizedMarker={customizedMarker}
							customizedContent={customizedContent}
						/>
					</Tab>

					{usuarioTitular && rol !== typesTiposUsuario.empleado ? (
						<Tab eventKey="certificaciones" title={<h5>Certificaciones adquiridas</h5>}>
							<CertificacionesAdquiridas folioContrato={folioContrato} />
						</Tab>
					) : null}
				</Tabs>
			</Container>
		</>
	);
};

export default CursoPropedeutico;
