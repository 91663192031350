import Loader from "@helpers/loader/loader";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Image, Navbar, Row, Form, FloatingLabel, Button } from "react-bootstrap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useWindowResize from "@customHooks/useWindowResize";
import { Typography } from "@mui/material";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import DirectorioTelefonico from "@components/usuario/CompraLicencia/modals/directorioTelefonico/DirectorioTelefonico";
import AvisoPrivacidad from "@components/usuario/CompraLicencia/modals/avisoPrivacidad/AvisoPrivacidad";
import GridPaquetes from "@components/usuario/CompraLicencia/GridPaquetes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { createCheckoutSession, getPriceAbono, getPriceCertificacionMosAdicional, getPriceCertificacionMosAdicionalExterna } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import Swal from "sweetalert2";
import { Notificacion } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import stripe from "@helpers/stripe/stripe";
import { useLocation } from "react-router-dom";

const CompraCertificacion = () => {
	const urlParams = new URLSearchParams(useLocation().search);

	const { contextValue, setContextValue } = useContext(UserContext);

	const [showLoader, setShowLoader] = useState(true);

	const [loading, setLoading] = useState(false);

	const [showModalAvisoPrivacidad, setShowModalAvisoPrivacidad] = useState(false);

	const [showModalDirectorioTelefonico, setShowModalDirectorioTelefonico] = useState(false);

	const [precioStripe, setPrecioStripe] = useState("");

	const [folioContrato, setFolioContrato] = useState("");

	const [currencyRequested, setCurrencyRequested]= useState('mxn')

	const darkTheme = createTheme({
		palette: {
			mode: "dark",
		},
	});

	const {
		size: { width },
	} = useWindowResize();

	const handleClickAvisoPrivacidad = () => {
		setShowModalAvisoPrivacidad(!showModalAvisoPrivacidad);
	};

	const handleClickDirectorioTelefonico = () => {
		setShowModalDirectorioTelefonico(!showModalDirectorioTelefonico);
	};

	const handleClickPreguntasFrecuentes = () => {
		let link = document.createElement("a");

		link.href = "https://globalclickmexico.com/faq/index.html";

		link.target = "_blank";

		link.dispatchEvent(new MouseEvent("click"));
	};

	const handleOnChange = ({ target }) => {
		setFolioContrato(target.value.replace(/\D/g, ""));
	};

	const generarCheckout = async (folioContratoParam = null) => {
		try {
            //TODO: cambiar la data de la sesion para redirigir al usuario a una pagina de success o de error
			const sessionData = {
				success_url: `${window.location.origin}${currencyRequested !== 'mxn' ? dashboardRoutesPaths.pagos.compra.compraCertificacionMosExternaRealizadaPublica : dashboardRoutesPaths.pagos.compra.compraCertificacionMosRealizadaPublica}`,
				cancel_url:  `${window.location.origin}${dashboardRoutesPaths.pagos.compra.compraCertificacionMos}?folioContrato=${folioContratoParam}`,
				line_items: [{ price: precioStripe, quantity: 1 }],
				mode: "payment",
				currency: currencyRequested,
				metadata: {
					folioContrato: folioContratoParam,
					metodoPago: "contado",
				},
			};

			const checkoutSession = await createCheckoutSession(sessionData);

			if (urlParams.get("folioContrato") === null) {
				const { isConfirmed } = await Swal.fire({
					title: "Saliendo de Click+",
					text: "Estás a punto de ser redirigido al sitio de pago",
					showCancelButton: true,
					reverseButtons: true,
					confirmButtonText: "Entendido",
					cancelButtonText: "Cancelar",
					confirmButtonColor: "#0d6efd",
					allowOutsideClick: false,
				});

				if (!isConfirmed) {
					return Notificacion("info", "El pago fue cancelado");
				}
			}

			const idRegionCompra = urlParams.get("idRegion");

			setContextValue({
				...contextValue,
				stripeSession: checkoutSession,
				idRegion: idRegionCompra,
				folioContrato: folioContratoParam === null ? folioContrato : folioContratoParam,
				isMobile: folioContratoParam === null ? false : true
			});

			await (await stripe).redirectToCheckout({ sessionId: checkoutSession.id });
		} catch (error) {
			console.log("Error al generar el checkout de pago: ", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			await generarCheckout();

			if (urlParams.get("folioContrato") === null) {
				setLoading(false);
			}
		} catch (error) {
			console.log("error al iniciar el pago de abono: ", error);

			setLoading(false);
		}
	};

    //TODO: Cambiar esta funcion para consultar el precio de la certificacion
	const consultarPrecioAbono = async () => {
		try {
			const montoLibre = true;

			const currency = urlParams.get("currency");
			
			let id_precio;

			if(currency) {
				setCurrencyRequested(currency)
				const {idPrecio} = await getPriceCertificacionMosAdicionalExterna();
				id_precio = idPrecio;
			}else{
				const {idPrecio} = await getPriceCertificacionMosAdicional();
				id_precio = idPrecio;
			}
			setPrecioStripe(id_precio);

			if (urlParams.get("folioContrato") === null) {
				setShowLoader(false);
			}
		} catch (error) {
			console.log("error al iniciar el pago de abono: ", error);
		}
	};

	useEffect(() => {
		// setShowLoader(false);

		if (precioStripe === "") {
            //TODO: Cambiar por la funcion de nueva certificacion
			consultarPrecioAbono();
		}
	}, []);

	useEffect(() => {
		if (precioStripe !== "") {
			const folioContratoParam = urlParams.get("folioContrato");

			console.log("folio param: ", folioContratoParam);

			if (folioContratoParam !== null) {
				// setFolioContrato(folioContratoParam);

				generarCheckout(folioContratoParam);

				// formRef?.current.click();
			}
		}
	}, [precioStripe]);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />

					<div data-bs-theme="dark" className="bg-main-container d-flex flex-column" style={{ overflow: "hidden", overflowY: "scroll" }}>
						<Navbar expand="lg" bg="dark" className="bg-dark-custom">
							<Container fluid>
								<Navbar.Brand href="#">
									<div className="m-1">
										<img className="pointer" src={require(`@assets/images/logo-gc-horizontal.png`)} alt="Logo Global Click" style={{ width: `${width > 992 ? "10vw" : "20vw"}` }} />
									</div>
								</Navbar.Brand>

								<div className="text-white d-flex justify-content-end">
									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickAvisoPrivacidad}>
										Aviso de privacidad
									</Typography>

									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickPreguntasFrecuentes}>
										Preguntas frecuentes
									</Typography>

									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickDirectorioTelefonico}>
										Directorio telefónico
									</Typography>
								</div>
							</Container>
						</Navbar>

						<Container className="py-30">
							<Row>
								<Col>
									<Container className="bg-light-dark card">
										<Row className="text-white">
											<Col>
												<Container className="py-3">
													<TitleDashboard title="Nueva compra de certificacion MOS" />

													<GridPaquetes />

													<TitleDashboard title="Ingresa tu Folio de Contrato" />

													<Form onSubmit={handleSubmit} className="mt-3 mx-4">
														<FloatingLabel label="Folio de Contrato" className="mb-3">
															<Form.Control maxLength={5} type="text" placeholder="Folio de contrato" name="folioContrato" value={folioContrato} onChange={handleOnChange} />
														</FloatingLabel>

														<div className="d-grid gap-2">
															<Button type="submit" variant="primary" size="lg">
																Pagar {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
															</Button>
														</div>
													</Form>
												</Container>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>

						<Container fluid className="p-0 align-content-end" style={{ flex: 1 }}>
							<Row>
								<Col>
									<Card>
										<Card.Body className="p-0">
											<Image fluid src={require("@assets/images/compra/compra-footer.jpg")} alt="Footer de Global Click México" className="w-100 h-100" />
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>

						<DirectorioTelefonico modalShow={showModalDirectorioTelefonico} setModalShow={setShowModalDirectorioTelefonico} />

						<AvisoPrivacidad modalShow={showModalAvisoPrivacidad} setModalShow={setShowModalAvisoPrivacidad} />
					</div>
				</ThemeProvider>
			)}
		</>
	);
};

export default CompraCertificacion;
