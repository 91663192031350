import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addLicencia = (licencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const licenciaAgregada = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.addLicencia}`,
        method: "POST",
        body: JSON.stringify(licencia),
      });

      resolve(licenciaAgregada);
    } catch (error) {
      // console.log("Error al agregar la licencia: ", error);

      reject(error);
    }
  });
};

export const addCursosLicencia = (licencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const licenciaAgregada = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.addCursosLicencia}`,
        method: "POST",
        body: JSON.stringify(licencia),
      });

      resolve(licenciaAgregada);
    } catch (error) {
      // console.log("Error al agregar la licencia: ", error);

      reject(error);
    }
  });
};

export const getLicencia = async (idLicencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: licencia } = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.getLicencia}/${idLicencia}`,
        method: "POST",
      });

      resolve(licencia);
    } catch (error) {
      // console.log("Error al obtener la licencia: ", error);

      reject(error);
    }
  });
};

// export const checkTipoLicencia = async (tipoLicencia) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const licencia = await httpRequest({
//         url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.checkTipoLicencia}/${tipoLicencia}`,
//         method: "POST",
//       });

//       resolve(licencia);
//     } catch (error) {
//       // console.log("Error al consultar el tipo de la licencia: ", error);

//       reject(error);
//     }
//   });
// };

export const updateLicencia = (licencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const licenciaActualizada = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.updateLicencia}`,
        method: "POST",
        body: JSON.stringify(licencia),
      });

      resolve(licenciaActualizada);
    } catch (error) {
      // console.log("Error al actualizar la licencia: ", error);

      reject(error);
    }
  });
};

export const deactivateLicencia = async (idLicencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const licenciaDesactivada = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.deactivateLicencia}/${idLicencia}`,
        method: "POST",
      });

      resolve(licenciaDesactivada);
    } catch (error) {
      // console.log("Error al desactivar la licencia: ", error);

      reject(error);
    }
  });
};

export const reactivateLicencia = async (idLicencia) => {
  return new Promise(async (resolve, reject) => {
    try {
      const licenciaReactivada = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.reactivateLicencia}/${idLicencia}`,
        method: "POST",
      });

      resolve(licenciaReactivada);
    } catch (error) {
      // console.log("Error al reactivar la licencia: ", error);

      reject(error);
    }
  });
};

export const getLicencias = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: licencias } = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.getLicencias}`,
        method: "GET",
      });

      resolve(licencias);
    } catch (error) {
      // console.log("Error al obtener las licencias: ", error);

      reject(error);
    }
  });
};

export const getLicenciasInvitado = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: licencias } = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.getLicenciasInvitado}`,
        method: "GET",
      });

      resolve(licencias);
    } catch (error) {
      // console.log("Error al obtener las licencias: ", error);

      reject(error);
    }
  });
};

export const getLicenciasNuevas = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: licencias } = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.getLicenciasNuevas}`,
        method: "GET",
      });

      resolve(licencias);
    } catch (error) {
      // console.log("Error al obtener las licencias: ", error);

      reject(error);
    }
  });
};

export const getCursosLicencia = async (idCredencial) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: cursosLicencia } = await httpRequest({
        url: `${endpointsPaths.licencias}/${httpRequestsPaths.licencias.getCursosLicencia}/${idCredencial}`,
        method: "POST",
      });

      resolve(cursosLicencia);
    } catch (error) {
      // console.log("Error al consultar los cursos de la licencia: ", error);

      reject(error);
    }
  });
};
