import React, { useContext } from "react";

import slider2 from "@assets/images/slider2.png";
import appMovil from "@assets/images/sliderAppMovil.png";
import chatIa from "@assets/images/chat-ia.png";
import sliderMisiones from "@assets/images/slider-misiones.png";
import salaEntretenimiento from "@assets/images/slider-juegos.png";
import agendaAsesoria from "@assets/images/agenda-asesoria.png";
import chatEnLinea from "@assets/images/chat-online.png";
import nuevoContenido from "@assets/images/SliderNuevoContenido.png"
import UserContext from "@context/User/UserContext";
import { Carousel } from "react-bootstrap";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { Backdrop, Box, Modal, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";


//   Fade.propTypes = {
// 	children: PropTypes.element.isRequired,
// 	in: PropTypes.bool,
// 	onClick: PropTypes.any,
// 	onEnter: PropTypes.func,
// 	onExited: PropTypes.func,
// 	ownerState: PropTypes.any,
//   };


const style = {
	height: "100vh",
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const SeccionSliderHome = ({ handleChangeComponent }) => {

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { contextValue, setContextValue } = useContext(UserContext);

	const handleCurso = (view, codigoCurso) => {
		setContextValue({
			...contextValue,
			codigoCursoSeleccionado: codigoCurso,
			codigoSeccionSeleccionada: codigoCurso + "01",
		});

		handleChangeComponent(view);
	};

	const handleMos = (view) => {
		handleChangeComponent(view);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
				<div className="mb-2" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
					<h5>Ingresa a Click+ Móvil</h5>
					<FontAwesomeIcon style={{cursor: 'pointer'}} onClick={handleClose} icon={faClose} color="red" size="lg"/>
				</div>
				<iframe
					style={{width: "100%", height: '90vh'}}
					// onLoad={onLoad}
					allow="autoplay; encrypted-media"
					loading="lazy"
					title="Lección actual"
					className="embed-responsive-item"
					src={'https://globalclicklatam.com/introduccionClick/introduccionInicioSesion.mp4'}
					></iframe>
				</Box>
			</Modal>
			<Carousel className="main-slider mt-4 mb-4">

				{/* <Carousel.Item onClick={handleOpen} className="pointer">
					<img src={appMovil} alt="slider-app" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item> */}

				<Carousel.Item>
					<img src={appMovil} alt="slider-app" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>

				<Carousel.Item >
					<img src={nuevoContenido} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>
				<Carousel.Item onClick={() => handleChangeComponent(multiViewComponentsPaths.agendasAsesorias)} className="pointer">
					<img src={agendaAsesoria} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>

				<Carousel.Item>
					<img src={chatEnLinea} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>

				<Carousel.Item onClick={() => handleChangeComponent(multiViewComponentsPaths.suiteAI)} className="pointer">
					<img src={chatIa} alt="slider-meta" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>

				<Carousel.Item onClick={() => handleMos("cursoPropedeuticoMos")} className="pointer">
					<img src={slider2} alt="slider-mos" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>

				<Carousel.Item className="pointer" onClick={() => handleChangeComponent(multiViewComponentsPaths.misiones)}>
					<img src={sliderMisiones} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
				</Carousel.Item>


				{/* <Carousel.Item className="pointer" onClick={() => handleChangeComponent(multiViewComponentsPaths.salaEntretenimiento)}>
				<img src={salaEntretenimiento} alt="slider-misiones" style={{ width: "100%", height: "100%" }} />
			</Carousel.Item> */}

			</Carousel></>
	);
};

export default SeccionSliderHome;
